import styled from "styled-components";
import { SignInBg, flexBox } from "../../style/Div.style";
import { useNavigate } from "react-router-dom";

const Join = () => {
  const navigate = useNavigate();
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init("cf863cc67ba502515ea1da6b174f97ac");
  }
  const onSignUpWithKakao = (type: string) => {
    const redirectUri = `${window.location.origin}/callback/kakaotalk`;
    // const scope = ["profile_nickname", "profile_image"].join(",");
    window.Kakao.Auth.authorize({
      redirectUri,
      // scope,
      state: type,
    });
  };
  return (
    <JoinContainer>
      <Logo>과외픽!</Logo>
      <SignInBg>
        <div>회원가입</div>
        <SelectBox>
          <div onClick={() => onSignUpWithKakao("teacher")}>
            <div>선생님</div>
            <div>학생을 구하고 있어요</div>
          </div>
          <div onClick={() => onSignUpWithKakao("student")}>
            <div>학생/학부모</div>
            <div>선생님을 구하고 있어요</div>
          </div>
        </SelectBox>
        <div>보안 및 본인인증을 위하 카카오 계정으로 로그인을 진행합니다.</div>
      </SignInBg>
    </JoinContainer>
  );
};

export default Join;

const JoinContainer = styled.div`
  ${flexBox("column", "center")}
  gap: 20px;
  margin-top: 120px;
`;

const Logo = styled.div`
  ${flexBox("column", "center", "center")}
  font-size: 40px;
  align-self: flex-start;
  width: 200px;
  height: 80px;
`;

const SelectBox = styled.div`
  ${flexBox("row", "center", "center")}
  gap: 20px;
  > div {
    ${flexBox("column", "center", "center")}
    gap: 20px;
    width: 246px;
    height: 153px;
    border-radius: 30px;
    > div:nth-child(1) {
      ${flexBox("row", "center")}
      gap: 10px;
      font-size: 27px;
      font-weight: bold;
    }
    > div:nth-child(2) {
      font-size: 18px;
    }
  }
  > div:nth-child(1) {
    border: 3px solid #5856e2;
    background-color: #ebecfc;
    > div:nth-child(1) {
      color: #361eab;
    }
    > div:nth-child(2) {
      color: #5856e2;
    }
  }
  > div:nth-child(2) {
    border: 3px solid #b3ce00;
    background-color: #e5f096;
    > div:nth-child(1) {
    }
    > div:nth-child(2) {
      color: #555555;
    }
  }
`;
