import { useRecoilValue } from "recoil";
import { isLoggedInAtom } from "../../atoms";
import styled from "styled-components";
import { BigBtn, flexBox } from "../../style/Div.style";
import { Header_0, Text_2 } from "../../style/Font.style";
import { theme } from "../../Theme";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  const isLoggedIn = useRecoilValue(isLoggedInAtom);
  return (
    <WelcomeContainer>
      <Title>회원가입이 완료되었습니다!</Title>
      <Subscription>
        {isLoggedIn === "student"
          ? "원하는 과목의 시범과외까지 편하게 받아보세요."
          : "이제 선생님 등록부터 시범과외까지 한번에 해결할 수 있어요."}
      </Subscription>
      <Buttons>
        <BigBtn
          bg={theme.Primary_2}
          color="white"
          onClick={() => navigate("/teacher")}
        >
          메인으로
        </BigBtn>
        <BigBtn
          bg={theme.Secondary_3}
          color={theme.Text_800}
          onClick={() => navigate("/teacher/profile")}
        >
          프로필 작성
        </BigBtn>
      </Buttons>
    </WelcomeContainer>
  );
};

export default Welcome;

const WelcomeContainer = styled.div`
  ${flexBox("column", "center")}
  gap: 20px;
  margin-top: 120px;
`;

const Title = styled.div`
  ${Header_0}
  color:${(props) => props.theme.Primary_2}
`;

const Subscription = styled.div`
  ${Text_2}
  color:${(props) => props.theme.Text_600}
`;

const Buttons = styled.div`
  ${flexBox("row", "center")}
  margin-top: 50px;
  gap: 20px;
  > div {
    ${Text_2}
  }
`;
