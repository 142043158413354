import styled from "styled-components";

export const flexBox = (
  direction: "row" | "column",
  align?: string,
  justify?: string
) => `
    display: flex;
    flex-direction: ${direction};
    ${align ? `align-items: ${align};` : ""}
    ${justify ? `justify-content: ${justify};` : ""}
  `;

export const BigBtn = styled.div<{ bg: string; color: string }>`
  ${flexBox("row", "center", "center")}
  width: 200px;
  height: 60px;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: 30px;
`;

export const MediumBtn = styled.div<{ bg: string; color: string }>`
  ${flexBox("row", "center", "center")}
  width: 130px;
  height: 60px;
  font-size: 18px;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: 30px;
`;

export const SmallBtn = styled.div<{ bg: string; color: string }>`
  ${flexBox("row", "center", "center")}
  width: 110px;
  height: 40px;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: 30px;
  font-size: 16px;
`;

export const SignInBg = styled.div`
  ${flexBox("column", "center", "center")}
  gap: 20px;
  width: 639px;
  padding: 60px;
  background-color: #fafafa;
  border-radius: 30px;
  > div:nth-child(1) {
    font-size: 36px;
    color: #5856e2;
  }
`;
