import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, OAuthProvider, signInWithCredential } from "firebase/auth";
import { provider, db } from "../../fbase";
import { arrayUnion, doc, getDoc, setDoc } from "firebase/firestore";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isLoggedInAtom, userUIDAtom } from "../../atoms";
const CallbackPage = () => {
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const setIsLoggedIn = useSetRecoilState(isLoggedInAtom);
  const setUserUID = useSetRecoilState(userUIDAtom);
  const navigate = useNavigate();
  const auth = getAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  if (!code) {
    navigate("/login");
  }
  const getKakaoToken = async (code: string) => {
    await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `grant_type=authorization_code&client_id=6e37c5fc6c0fcbb4e5008cb928d25917&redirect_uri=${window.location.origin}/callback/kakaotalk&code=${code}`,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.id_token) {
          setIdToken(data.id_token);
        } else {
          navigate("/");
        }
        if (data.access_token) {
          setAccessToken(data.access_token);
        }
      });
  };

  const signUpUser = async (
    uid: string,
    nickname: string,
    imageUrl: string
  ) => {
    if (state) {
      if (state === "teacher") {
        await setDoc(doc(db, "teacher", uid), {
          nickname: nickname,
          image_url: imageUrl,
        });
        console.log("sign up");
        navigate("/teacher");
      }
      if (state === "student") {
        await setDoc(doc(db, "student", uid), {
          nickname: nickname,
        });
        console.log("sign up");
        navigate("/student");
      }
    } else {
      navigate("/join");
    }
  };

  useEffect(() => {
    if (code) {
      getKakaoToken(code);
    }
  }, []);

  useEffect(() => {
    if (idToken && accessToken) {
      const credential = provider.credential({
        idToken: idToken,
      });
      signInWithCredential(auth, credential)
        .then(async (result) => {
          console.log({ result });
          setUserUID(result.user.uid);
          const [isSignedUpT, isSignedUpS] = await Promise.all([
            getDoc(doc(db, "teacher", result.user.uid)),
            getDoc(doc(db, "student", result.user.uid)),
          ]);
          if (!isSignedUpS.exists() && !isSignedUpT.exists()) {
            signUpUser(
              result.user.uid,
              result.user.providerData[0].displayName ?? "nickname",
              result.user.providerData[0].photoURL ?? ""
            );
          }
          if (isSignedUpS.exists()) {
            setIsLoggedIn("student");
            navigate("/student");
          }
          if (isSignedUpT.exists()) {
            setIsLoggedIn("teacher");
            navigate("/teacher");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [idToken, accessToken]);

  return <div></div>;
};

export default CallbackPage;
