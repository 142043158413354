import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import Header from "./components/header/Header";
import { Route, Routes } from "react-router-dom";
import Landing from "./screens/landing/Landing";
import Student from "./screens/student/Student";
import Teacher from "./screens/teacher/Teacher";
import Join from "./screens/join/Join";
import Login from "./screens/login/Login";
import CallbackPage from "./screens/callback/Callback";
import { theme } from "./Theme";
import Welcome from "./screens/welcome/Welcome";
import MyPageTeacher from "./screens/teacher/MyPageTeacher";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Header />
        <Body>
          <Routes>
            <Route path="" element={<Landing />} />
            <Route path="/student/*" element={<Student />} />
            <Route path="/teacher" element={<Teacher />} />
            <Route path="teacher/mypage" element={<MyPageTeacher />} />
            <Route path="/join" element={<Join />} />
            <Route path="/login" element={<Login />} />
            <Route path="/callback/*" element={<CallbackPage />} />
            <Route path="/welcome" element={<Welcome />} />
          </Routes>
        </Body>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-y: auto;
  overflow-y: hidden;
  overflow-x: scroll;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 57px);
  overflow: auto;
  align-self: center;
`;
