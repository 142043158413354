import styled from "styled-components";
import { flexBox } from "../../style/Div.style";
import { Header_1, Header_3, Text_2 } from "../../style/Font.style";
import { useState } from "react";
import Profile from "./profile/Profile";

const MyPageTeacher = () => {
  const [option, setOption] = useState<"profile" | "trial">("profile");
  return (
    <MyPageContainer>
      <Header>
        <div>마이페이지</div>
        <div>{option === "profile" ? "내 프로필" : "나의 시범과외"}</div>
      </Header>
      <Body>
        <SideBar>
          <Tab
            isSelected={option === "profile"}
            onClick={() => setOption("profile")}
          >
            내 프로필
          </Tab>
          <Tab
            isSelected={option === "trial"}
            onClick={() => setOption("trial")}
          >
            나의 시범과외
          </Tab>
        </SideBar>
        {option === "profile" ? <Profile /> : <></>}
      </Body>
    </MyPageContainer>
  );
};

export default MyPageTeacher;

const MyPageContainer = styled.div`
  ${flexBox("column", "center")}
`;
const Header = styled.div`
  ${flexBox("row")};
  height: 165px;
  width: 1200px;
  border-bottom: 2px solid #c4c4c4;
  > div:nth-child(1) {
    ${flexBox("column", "flex-start", "flex-end")}
    width: 290px;
    height: 100%;
    padding: 30px;
    border-bottom-right-radius: 30px;
    background-color: ${(props) => props.theme.Primary_2};
    ${Header_3};
    color: white;
  }
  > div:nth-child(2) {
    ${Header_1}
    color:${(props) => props.theme.Text_800};
    margin-left: 50px;
    padding: 30px;
    align-content: flex-end;
  }
`;
const Body = styled.div`
  ${flexBox("row")}
  width: 1200px;
`;

const SideBar = styled.div`
  ${flexBox("column", "center")}
  width: 290px;
  margin-top: 60px;
`;
const Tab = styled.div<{ isSelected: boolean }>`
  align-content: center;
  width: 262px;
  height: 55px;
  padding: 15px;
  border-radius: 15px;
  ${Text_2};
  color: ${(props) => props.theme.Primary_2};
  background-color: ${(props) => props.isSelected && "#EBECFC"};
`;
