import styled from "styled-components";
import { SignInBg, flexBox } from "../../style/Div.style";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    Kakao: any;
  }
}

const Login = () => {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init("cf863cc67ba502515ea1da6b174f97ac");
  }
  const onLoginWithKakao = () => {
    const redirectUri = `${window.location.origin}/callback/kakaotalk`;
    // const scope = ["profile_nickname", "profile_image"].join(",");
    window.Kakao.Auth.authorize({
      redirectUri,
      // scope,
    });
  };

  return (
    <LoginContainer>
      <Logo>과외픽!</Logo>
      <SignInBg>
        <div>로그인</div>
        <KakaoLogin onClick={onLoginWithKakao}>카카오 계정 로그인</KakaoLogin>
        <div>보안 및 본인인증을 위하 카카오 계정으로 로그인을 진행합니다.</div>
      </SignInBg>
    </LoginContainer>
  );
};

export default Login;

const LoginContainer = styled.div`
  ${flexBox("column", "center")}
  gap: 20px;
  margin-top: 120px;
`;

const Logo = styled.div`
  ${flexBox("column", "center", "center")}
  font-size: 40px;
  align-self: flex-start;
  width: 200px;
  height: 80px;
`;

const KakaoLogin = styled.div`
  width: 339px;
  height: 60px;
  margin-top: 60px;
  ${flexBox("column", "center", "center")}
  background-color:#5856E2;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  color: white;
`;
