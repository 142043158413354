import { useRecoilValue } from "recoil";
import { userUIDAtom } from "../../atoms";

const Teacher = () => {
  const userUID = useRecoilValue(userUIDAtom);
  console.log(userUID);
  return <div>Enter</div>;
};

export default Teacher;
