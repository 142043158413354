import styled from "styled-components";
import { flexBox } from "../../style/Div.style";

const Landing = () => {
  return <LandingContainer></LandingContainer>;
};

export default Landing;

const LandingContainer = styled.div`
  ${flexBox("column", "center")};
  gap: 40px;
  padding: 20px;
  width: 100%;
`;
