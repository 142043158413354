export const Header_0 = `
    font-size: 36px;
    font-weight: bold;
`;

export const Header_1 = `
    font-size: 28px;
    font-weight: bold;
`;

export const Header_3 = `
    font-size:21px;
    font-weight:bold;
`;

export const Text_2 = `
    font-size: 18px;
    font-weight: bold;
`;

export const List_0 = `
    font-size: 16px;
    line-height: 25px;
`;

export const List_1 = `
    font-size: 16px;
    line-height: 30px;
`;
