import styled from "styled-components";
import { BigBtn, MediumBtn, flexBox } from "../../style/Div.style";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <div>
        <Logo onClick={() => navigate("")}>과외픽!</Logo>
        <Content>
          <div>과외픽 소개</div>
          <div>과외픽 사용법</div>
          <div>1:1 문의</div>
        </Content>
      </div>
      <Buttons>
        <MediumBtn bg="#5856E2" color="white" onClick={() => navigate("login")}>
          login
        </MediumBtn>
        <MediumBtn bg="#898DEE" color="white" onClick={() => navigate("join")}>
          join
        </MediumBtn>
      </Buttons>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  ${flexBox("row", "center", "space-between")}
  width: 100%;
  height: 90px;
  border-bottom: 1px solid black;
  > div {
    ${flexBox("row")}
  }
`;

const Logo = styled.div`
  font-size: 40px;
  margin-left: 57px;
  color: #5856e2;
`;

const Content = styled.div`
  ${flexBox("row", "center")};
  gap: 53px;
  margin-left: 170px;
  > div {
    font-size: 18px;
  }
`;

const Buttons = styled.div`
  ${flexBox("row", "center")};
  gap: 20px;
  margin-right: 57px;
  font-size: 18px;
`;
