import styled from "styled-components";
import { SmallBtn, flexBox } from "../../../style/Div.style";
import { useRecoilValue } from "recoil";
import { userUIDAtom } from "../../../atoms";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../fbase";
import { Header_3, List_0, List_1 } from "../../../style/Font.style";
import { theme } from "../../../Theme";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const userUID = useRecoilValue(userUIDAtom);
  const [userProfile, setUserProfile] = useState<{
    nickname: string;
    education: { university: string; major: string };
    age: number;
    gender: "male" | "female" | undefined;
    phoneNumber: string;
    ImageUrl: string;
    subscription: string;
    schedule: string;
  }>({
    nickname: "",
    education: { university: "", major: "" },
    age: 0,
    gender: undefined,
    phoneNumber: "",
    ImageUrl: "",
    subscription: "",
    schedule: "",
  });
  const getUserProfile = async () => {
    const userProfile = (await getDoc(doc(db, "teacher", userUID))).data();
    setUserProfile({
      nickname: userProfile?.nickname ?? "",
      education: userProfile?.education ?? { university: "", major: "" },
      age: userProfile?.age ?? 0,
      gender: userProfile?.gender ?? undefined,
      phoneNumber: userProfile?.phone_number ?? "",
      ImageUrl: userProfile?.image_url ?? "",
      subscription: userProfile?.subscription ?? "",
      schedule: userProfile?.schedule ?? "",
    });
  };

  useEffect(() => {
    getUserProfile();
  }, [userUID]);
  return (
    <ProfileContainer>
      <div>
        <SmallBtn
          bg={theme.Secondary_3}
          color={theme.Text_800}
          onClick={() => navigate("/teacher/profile/edit")}
        >
          수정하기
        </SmallBtn>
      </div>
      <div>
        <Title>기본 정보</Title>
        <BasicInfo>
          <img src={userProfile.ImageUrl} />
          <div>
            <div>{userProfile.nickname ?? "닉네임"}</div>
            <div>
              {userProfile.education.university} / {userProfile.education.major}
            </div>
            <div>
              {userProfile.age} /{" "}
              {userProfile.gender === "male"
                ? "남자"
                : userProfile.gender === "female"
                ? "여자"
                : "-"}
              {"   |   "}
              {userProfile.phoneNumber}
            </div>
          </div>
        </BasicInfo>
      </div>
      <div>
        <Title>소개 / 경력</Title>
        <Subscription>{userProfile.subscription}</Subscription>
      </div>
      <div>
        <Title>과외 일정</Title>
        <Subscription>{userProfile.schedule}</Subscription>
      </div>
    </ProfileContainer>
  );
};

export default Profile;

const ProfileContainer = styled.div`
  ${flexBox("column")}
  gap: 30px;
  margin-left: 80px;
  > div:nth-child(1) {
    padding: 10px;
    align-self: flex-end;
  }
`;

const Title = styled.div`
  width: 746px;
  border-bottom: 2px solid #e9e9e9;
  ${Header_3};
  color: ${(props) => props.theme.Primary_1};
  font-weight: normal;
  padding: 20px;
`;

const BasicInfo = styled.div`
  ${flexBox("row", "center")};
  gap: 50px;
  padding: 20px;
  margin-left: 10px;
  > img {
    width: 130px;
    height: 130px;
    border-radius: 130px;
  }
  > div {
    ${flexBox("column")}
    gap: 10px;
    > div {
      ${List_0}
      color: ${(props) => props.theme.Text_800};
    }
  }
`;

const Subscription = styled.div`
  ${flexBox("column")}
  gap: 10px;
  padding: 20px;
  margin-left: 10px;
  > div {
    ${List_1}
    color: ${(props) => props.theme.Text_800};
  }
`;
